import React from "react";
import SiteLayout from "@layouts/site-layout";
import BgContainer from "@components/BgContainer";

const index = () => {
  return (
    <SiteLayout>
      <div className="home_middle_sec top_margin_comn pt-3 px-2 d-flex flex-column align-items-center justify-content-center">
        <div className="py-5">
          <span className="headerBoldText text-center">TERMS OF USE</span>
        </div>
        <BgContainer text="Introduction" />
        <div className="container textsContainer container">
          <span className="textGray">
            Welcome to STEPapp operated by EDUISFUN TECHNOLOGIES PVT LTD.
          </span>
          <p className="textGray">
            BY ACCESSING THE STEPAPP APPLICATION{" "}
            <strong>(“APPLICATION” OR “APP”)</strong> OR THE WEBSITE FOUND AT{" "}
            <a
              className="textGray"
              style={{ color: "#4545B0" }}
              href="http://www.stepapp.ai"
              target="_blank"
            >
              http://www.stepapp.ai
            </a>{" "}
            or{" "}
            <a
              className="textGray"
              style={{ color: "#4545B0" }}
              href="http://learn.stepapp.in"
              target="_blank"
            >
              http://learn.stepapp.in
            </a>{" "}
            OR ANY OTHER WEB ADDRESS BERING SUB-DOMAIN OF THE DOMAIN STEPAPP,
            WHETHER THROUGH A MOBILE DEVICE, MOBILE APPLICATION OR COMPUTER
            (COLLECTIVELY CALLED AS THE <strong>“SERVICES”</strong>), THE USER
            OF THE SERVICES (HEREINAFTER REFERRED TO AS <strong>“YOU”</strong>{" "}
            OR <strong>“USER”</strong>) HEREBY EXPRESSLY AGREE THAT THE TERMS OF
            SERVICE (<strong>“THE AGREEMENT”</strong> OR{" "}
            <strong>“THE TERMS”</strong>) IS LEGALLY BINDING ON YOU AND YOU GIVE
            YOUR FREE CONSENT TO BE GOVERNED BY THE PROVISIONS OF THE TERMS OF
            SERVICE. FURTHER, THESE TERMS OF SERVICE CONSTITUTE A LEGALLY
            BINDING AGREEMENT IN RESPECT OF THE SERVICES OR ANY OTHER PRODUCT OR
            SERVICES DEVELOPED IN THE FUTURE THAT MAY BE PROVIDED THROUGH THE
            APPLICATION / WEBSITE BETWEEN YOU AND THE COMPANY (INCLUDING ALL ITS
            SUBSIDIARIES, RELATED AND/OR ASSOCIATED COMPANIES) (THESE ENTITIES
            ARE COLLECTIVELY REFERRED TO AS <strong>“THE COMPANY”</strong>,{" "}
            <strong>“WE”</strong>, <strong>“US”</strong> OR{" "}
            <strong>“OUR”</strong>), THE OWNER OR PROPRIETOR OF ALL THE RIGHTS
            IN THE APP OR RIGHTS ATTACHED THE APP AND/OR SERVICES. SUPPLEMENTAL
            TERMS AND CONDITIONS MAY APPLY TO SOME OF OUR SERVICES SUCH AS RULES
            FOR A PARTICULAR CONTEST, CONTENT, MEMBERSHIP, OR OTHER ACTIVITIES
            ACCESSIBLE THROUGH THE SERVICES. IF AND WHEN APPLICABLE SUCH
            SUPPLEMENTAL TERMS MAY BE COMMUNICATED/ NOTIFIED TO YOU VIA THE APP
            OR ANY OTHER APPROPRIATE MODE IN CONNECTION WITH SUCH CONTEST,
            CONTENT, OR SERVICES. THE PROVISIONS OF SUPPLEMENTAL TERMS SHALL
            PREVAIL OVER THESE TERMS IN CASE OF CONFLICT BETWEEN THESE TERMS AND
            SUPPLEMENTAL TERMS TO THE EXTENT OF SUCH CONFLICT. YOU SHALL BE
            RESPONSIBLE FOR REVIEWING THESE TERMS OR THE SUPPLEMENTAL TERMS AS
            ARE NOTIFIED / UPDATED FROM TIME TO TIME AND BY CONTINUING TO USE
            THE SERVICES YOU WILL BE DEEMED TO HAVE AGREED TO AND ACCEPTED ANY
            CHANGE OR AMENDMENTS TO THESE TERMS. IF YOU DO NOT AGREE TO ANY
            CHANGE TO THESE TERMS, YOU MUST DISCONTINUE USING OUR SERVICES
            FORTHWITH.
          </p>
          <span className="textGray">
            THESE TERMS CONSTITUTE RULES AND REGULATIONS AND USER AGREEMENT FOR
            THE ACCESS OF THE PLATFORM AND THE SAME ARE BEING PUBLISHED ON THE
            PLATFORM IN TERMS OF RULE 3(1) OF THE INFORMATION TECHNOLOGY
            (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE) RULES, 2021.
            BY REGISTERING FOR, ACCESSING, BROWSING, OR USING THE WEBSITE/S OR
            APP / PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND
            AGREE TO BE BOUND BY THESE TERMS OF USE INCLUDING THE PRIVACY POLICY
            OF THE COMPANY AVAILABLE AT{" "}
            <a
              className="textGray"
              style={{ color: "#4545B0" }}
              href="http://www.stepapp.ai/privacy"
            >
              (HTTP://WWW.STEPAPP.AI/PRIVACY-POLICY)
            </a>
            . IF YOU DO NOT AGREE TO ANY OF THE TERMS YOU AGREE NOT TO USE THE
            SERVICES OR PLATFORM.
          </span>
        </div>
        <BgContainer text="DEFINITIONS AND INTERPRETATION" />
        <div className="textsContainer container">
          <span
            style={{ fontWeight: 600, fontSize: "clamp(1rem, 2.5vw, 1.5rem)" }}
          >
            Definitions
          </span>
          <p className="textGray">
            <strong>“Applicable Law”</strong> shall mean all applicable Indian
            laws, by-laws, rules, regulations, orders ordinances, protocols,
            codes, guidelines, policies, notices, directions, judgments,
            decrees, or other requirements or official directives of any
            governmental authority or person acting under the authority of any
            governmental authority of India;
          </p>
          <p className="textGray">
            <strong>“Content”</strong> shall include (but is not limited to) any
            data, content, images, videos, location data, or other materials or
            information of any type that is available through the Services and
            shall include Free Content;
          </p>
          <p className="textGray">
            <strong>“Intellectual Property Rights”</strong> shall mean
            registered and unregistered rights in all forms of intellectual
            property subsisting under the laws of India and all analogous rights
            subsisting under the laws of other jurisdictions and shall include
            any legally protectable product or process of the human intellect
            whether registrable as patents, trademarks, copyrights, designs or
            otherwise such as an invention, expression or literary creation,
            unique name, trade secret, business method, database, industrial
            process, computer program, source code, process or presentation,
            visual interfaces, graphics, design, compilation, information.
            content, educational videos, and exercises;
          </p>
          <p className="textGray">
            <strong>“Company Data”</strong> shall mean any and all information
            available with the Company or provided to the Company by the User at
            his sole discretion in order to use the Services;
          </p>
          <p className="textGray">
            <strong>“Services”</strong> shall mean and include the services
            provided by the Company: Through its website: http://www.stepapp.ai
            or http://learn.stepapp.in or any other address of the Company with
            having the above domain name with a different sub-domain name.
          </p>
          <p className="textGray">
            Through its Application: on google play or android or any other
            platforms/ digital stores available now or in the future.
          </p>
          <p className="textGray">
            <strong>“Free Content”</strong> means any Content available for free
            to You through the Services.
          </p>
          <p className="textGray">
            <strong>“Platform”</strong> means StepApp website/s and its related
            Application Programming Interface (‘APIs’) mobile applications,
            Services provided through such website/applications, and any other
            product or service that the Company may provide now or in the
            future.
          </p>
          <p className="textGray">
            <strong>“User Content”</strong> shall mean Content that You upload,
            share or transmit to, through, or in connection with the Platform,
            such as likes, ratings, reviews, images, photos, messages, profile
            information, and any other materials that you publicly display or
            displayed in your account profile; and
          </p>
          <p className="textGray">
            <strong>"User/ Users"</strong> shall mean teachers, educational
            institutes, students, or any person that uses or visits the
            Platform.
          </p>
        </div>
        <div className="textsContainer container">
          <span
            style={{ fontWeight: 600, fontSize: "clamp(1rem, 2.5vw, 1.5rem)" }}
          >
            Interpretation
          </span>
          <p className="textGray">
            The Terms shall be interpreted according to the following provisions
            unless the context requires otherwise:
          </p>
          <p className="textGray">
            (1) references to the provisions of any law shall include such
            provisions as amended, re-enacted, or consolidated from time to time
            in so far as such amendment, re-enactment or consolidation applies
            or is capable of applying to Terms/ Services;
          </p>
          <p className="textGray">
            (2) the headings of clauses, are included for convenience only and
            shall not affect the interpretation of contents of the Terms;
          </p>
          <p className="textGray">
            (3) words importing the singular number shall include the plural and
            vice versa, and words importing either gender shall include all
            genders; and
          </p>
          <p className="textGray">
            (4) general words preceded or followed by words such as “other” or
            “including” or “particularly” shall not be given a restrictive
            meaning because they are preceded or followed by particular examples
            and shall be given meaning intended to fall within the meaning of
            the general words.
          </p>
        </div>
        <BgContainer text="ELIGIBILITY FOR ACCOUNT CREATION" />
        <div className="textsContainer container">
          <p className="textGray">
            Neither the websites nor the App shall be available to any user who
            has been previously suspended or removed from the website by the
            Company or any person under the age of 18 whose registration has not
            been or was not approved by a parent or legal guardian. Once “I
            Agree” button is clicked by the user, while using or registering an
            account with us or the Website, or the App, We presume that you have
            confirmed and represented the following:
          </p>
          <p className="textGray">
            (a) You have not been previously suspended or removed from accessing
            the Services;
          </p>
          <p className="textGray">
            (b) You have attained the age of majority under the Indian laws or
            your parent and/or legal guardian has given their free consent to
            your use of the Website or the App;
          </p>
          <p className="textGray">
            (c) Your registration and use of the Website or the App follows all
            applicable laws and regulations which govern the Website or the App
            or the Company.
          </p>
          <p className="textGray">
            Your Account: While registering and creating an account with the App
            or with the Website, you may be asked to provide your password in
            the form of a one-time-password (OTP) for the creation and usage of
            the account. The Company or the Website or the App does not take the
            responsibility of confidentiality of your account and the OTP if you
            divulge such OTP or account information to any third person and in
            such a case, you shall be solely responsible for all the
            activity(ies) done in your account. You further agree that all the
            credentials or information provided by you to the App or the Website
            are true, accurate, genuine updated, and complete and shall be
            updated from time to time. The App or the company does not take the
            responsibility to authenticate the veracity of the information
            provided by you while registering or using the app or the website.
          </p>
          <p className="textGray">
            The personal information including the username and password
            collected from You at the time of creating an account/ registering
            with us will be collected stored, and processed in accordance with
            our Privacy Policy (http://www.stepapp.ai/privacy policy)
          </p>
        </div>
        <BgContainer text="USER / CHILD USER" />
        <div className="textsContainer container">
          <p className="textGray">
            In case You are a User of the Services, and you are below the age of
            majority (“Child User”) then you may not be allowed to use the
            Services unless your parent and/or legal guardian has consented on
            your behalf for the use of the Services. A Child User may not have
            full or any access to the Services or restricted registration
            process unless the parent or the legal guardian has consented for
            the registration or usage of the Services on behalf of such Child
            User. To enable the usage of the Services by a Child User his or her
            parent or legal guardian may create, register, manage and approve
            Child User accounts only for his/her own child or for child for whom
            they are acting as a legal guardian. The parent and/or the legal
            guardian of such Child User you assume all the responsibility and
            liability on behalf of such child user and you represent that you
            represent and warrant that you are such Child User’s parent or legal
            guardian and you agree to be bound by the terms on behalf of such
            Child User, including without limitation being liable for all use of
            the website or of the app by the Child User. As a parent and or the
            legal guardian of the Child User you acknowledge, without any
            reservations, and agree that the Company may make any inquiries,
            directly or indirectly, in order to validate your registration
            information and the Company has all the rights to take appropriate
            legal action against such parent or the legal guardian who
            misrepresents and conceal about their personal information or is
            otherwise unlawful about their identity.
          </p>
        </div>
        <BgContainer text="LIMITED GRANT OF LICENSE" />
        <div className="textsContainer container">
          <p className="textGray">
            Subject to your compliance with these Terms of Service and
            applicable laws, we hereby grant you a limited non-exclusive,
            non-transferable license to download and install one copy of the App
            on your mobile device and to run such copy of the App solely for
            your own personal use. We do not grant any license nor grant any
            rights to you in relation to our intellectual property rights
            (including, without limitation, copyright, trademarks, logos,
            graphics, photographs, animations, videos, and text or rights in the
            App or rights attached to the App) or the intellectual property of
            our retail or advertising partners, other than the non-transferable,
            personal right to use and receive the Services in accordance with
            these Terms.
          </p>
          <p className="textGray">
            You further agree that you will not use, license, sub-license, sell,
            resell, transfer, assign, distribute or otherwise commercially
            exploit or make available to any third party the Services or the App
            in any way; modify or make derivative works based upon the Services
            or App or reverse engineer; create Internet “links” to the Services
            or “frame” or “mirror” any App on any other server or wireless or
            Internet-based device; reverse engineer or access the App in order
            to design or build a competitive product or service, design or build
            a product using similar ideas, features, functions or graphics of
            the Services or App, or copy any ideas, features, functions or
            graphics of the Services or App, or launch an automated program or
            script, including, but not limited to, web spiders, web crawlers,
            web robots, web ants, web indexers, bots, viruses or worms, or any
            program which may make multiple server requests per second, or
            unduly burdens or hinders the operation and/or performance of the
            Services or App remove any copyright, trademark or other proprietary
            notices from any portion of the Services attempt to gain
            unauthorized access to or impair any aspect of the Services or its
            related systems or networks.
          </p>
          <p className="textGray">
            You further agree that you will not circumvent or disable any
            content protection system or digital right management technology
            used in any of our Services and shall not use any of our Services
            which is either in derogation or violation of applicable laws of
            India.
          </p>
          <p className="textGray">
            As and when required, the App or the Company may permit You and
            other Users to post notes, questions, comments, ratings, reviews,
            images, videos, and other audio-visual materials and communications
            (collectively, “User Postings”) and the posting, creation, or
            modification by You of the computer code, being the source code or
            object of User Postings (including source code and object code)
            (“User Code”) (User Postings and User Code, collectively, “User
            Content”). You understand that whether such User Content is
            published, we do not guarantee any confidentiality with respect to
            any submissions.
          </p>
        </div>
        <BgContainer text="USER CONTENT LICENSE" />
        <div className="textsContainer container">
          <p className="textGray">
            To the extent permitted by law and where the same is not in conflict
            with Privacy Policy, by posting, submitting, or distributing User
            Content on or through the Website, You hereby grant to us a
            worldwide, non-exclusive, transferable, assignable, fully paid-up,
            royalty-free, perpetual, irrevocable right and license to host,
            transfer, display, perform, reproduce, distribute, prepare
            derivative works of, use, make, have made, sell, offer for sale,
            import, and otherwise exploit your User Content (not being any
            personal information), in whole or in part, in any media formats and
            through any media channels (now known or hereafter developed) for
            the purpose of providing better service or improved Service or to
            develop a new product for our Users. We may collect, store and
            analyze Your timing, accuracy, strength, weakness, and performance
            response, while you play and learn via the App, to guide or mentor
            you in your specified area of study or the syllabus including but
            not limited to the syllabus which you opt in the App for your
            studies.
          </p>
          <p className="textGray">
            Further, the Company shall have the right to monitor the download
            and usage of the Application/Services/products and the contents
            thereof by the User, to analyze such usage, and discuss the same
            with the User to enable effective and efficient usage of the
            Services. The User expressly permits the Company to clear their
            doubts using the Application/Services/online portal by answering the
            questions placed before it, providing study plans, informing of the
            progress, providing feedback, communicating with the student, and
            mentoring the student through telephone or e-mail on express consent
            of the legal guardian/parent of the User or through any other forum.
          </p>
          <p className="textGray">
            You shall be solely responsible for your User Content and the
            consequences of posting, creating, or publishing them. You further
            represent and warrant that:
          </p>
          <p className="textGray">
            (1) You are the creator and owner of or have the necessary licenses,
            rights, consents, and permissions to use and to authorize us and our
            Users to use and distribute your User Content as necessary to
            exercise the licenses granted by You in these Terms and in the
            manner contemplated by us and these Terms;
          </p>
          <p className="textGray">
            (2) Your User Content does not and will not: (a) infringe, violate,
            or misappropriate any third-party right, including any copyright,
            trademark, patent, trade secret, moral right, privacy right, right
            of publicity, or any other intellectual property or proprietary
            right or (b) slander, defame, libel, or invade the right of privacy,
            publicity or other property rights of any other person; and (c)
            upload on the Platform or Your Account, any content that is contrary
            to the Terms, guidelines of the Company, the Privacy Policy of the
            Company or any other policy of the Company, as may be updated from
            time to time or that is contrary to the applicable laws and
            regulations and where any such content is uploaded in any manner and
            for whatsoever reason, You shall ensure that such content is deleted
            from the Platform or Your account immediately.
          </p>
          <p className="textGray">
            (3) Your User Content does not contain any viruses, adware, spyware,
            worms, or other malicious code.
          </p>
          <p className="textGray">
            Further, the Company reserves the right to permit Users to share
            their User Content with a select group of other Users or make their
            User Content public for all (even non-Website users) to view. You
            acknowledge and agree that, although we may provide certain features
            intended to allow you to restrict some User Content you create from
            others, we do not guarantee that such User Content will never be
            accessible by others. In the event of unauthorized access, we will
            use reasonable efforts to notify you. We hereby disclaim all
            liability with respect to any unauthorized access to any restricted
            user content.
          </p>
        </div>
        <BgContainer text="USER INTERACTION WITH OTHERS" />
        <div className="textsContainer container">
          <p className="textGray">
            You shall be solely responsible for your interactions with other
            users. You understand that the Company currently does not conduct
            criminal background checks or screenings on its Users. The Company
            also does not inquire into the backgrounds of all or any of its
            users or attempt to verify the statements of its users. The Company
            makes no representations or warranties as to the conduct of users or
            their compatibility with any current or future users. The company
            reserves the right to conduct any criminal background check or other
            screenings, at any time and using available public records.
          </p>
          <p className="textGray">
            Under no circumstances the Company including but not limited to its
            affiliates, subsidiary, holding companies or partners be responsible
            or liable (whether directly or indirectly) for any losses or damages
            whatsoever, whether direct or indirect, general, special,
            compensatory, consequential, and/or incidental, arising out of or in
            relation to the conduct of yours’ or anyone else in connection with
            the use of the Service including but not limited to emotional
            distress, and/or any other damages resulting from communications or
            meeting with other users or persons you meet through the Service.
          </p>
        </div>
        <BgContainer text="RENEWAL OF SUBSCRIPTION BY YOU" />
        <div className="textsContainer container">
          <p className="textGray">
            You have the option to cancel your subscription for any additional
            feature of the App at any time. Once you choose to end your
            subscription for an additional feature of the App or the Service,
            your subscription will not be renewed on the next auto-renewal date
            and you may have access to use the additional feature of the App
            till such date, after which your account will be automatically
            down-graded or access to additional features of the App will be
            restricted or blocked. The Company shall neither be liable to give
            you any refund in respect of the period from the date of
            cancellation to the renewal date nor shall it be liable to give You
            any rebate/ discount for your renewal after cancellation should You
            choose to re-start the subscription at a future date.
          </p>
        </div>
        <BgContainer text="CANCELLATION OF SUBSCRIPTION / TERMINATION OF SERVICE" />
        <div className="textsContainer container">
          <p className="textGray">
            We reserve the right to cancel your subscription for Services at any
            point in time for any reason, with or without notice where you are
            found to be in violation or breach of the Terms.
          </p>
        </div>
        <BgContainer text="CURRENCY FLUCTUATION" />
        <div className="textsContainer container">
          <p className="textGray">
            The Website billing and payment services operate in INR (Indian
            Rupees) and therefore the Company shall neither be liable for any
            currency fluctuations or any other bank charges that occur when
            billing or debiting a credit or debit card denominated in a currency
            other than INR nor shall the Company be responsible for currency
            fluctuations or bank charges that occur when receiving or sending
            payment via wire transfer, check or automated clearing house to and
            from your bank account, credit card account or PayPal or similar
            payment account.
          </p>
        </div>
        <BgContainer text="REFUND" />
        <div className="textsContainer container">
          <p className="textGray">
            Unless otherwise required by law, paid subscription of the App is
            non-refundable irrespective of the usage of such subscription by
            You. You agree that you shall not demand any kind of refund for a
            prepaid subscription of the App or the Service at any point in time
            for whatsoever reason.
          </p>
        </div>
        <BgContainer text="CHANGE IN SUBSCRIPTION PRICE" />
        <div className="textsContainer container">
          <p className="textGray">
            The Company reserves the right to change the pricing of the paid
            subscription of the App from time to time. Any change in such price
            shall be communicated to you or displayed on the App at the time of
            usage of the Service or of the App.
          </p>
        </div>
        <BgContainer text="PROHIBITED ACTIVITIES" />
        <div className="textsContainer container">
          <p className="textGray">
            You may only engage in educational and study-related activities
            while using the Service. The following are examples of the kind of
            activity that is prohibited on the Service. We reserve the right to
            investigate and take appropriate legal action against anyone who, in
            our sole discretion, violates this provision, including without
            limitation, terminating your account and/or access to the Service,
            reporting you to appropriate governmental authorities, including law
            enforcement, or seeking other legal remedies. In particular, the
            User/ You are prohibited from, including but not limited to,
          </p>
          <p className="textGray">
            a. Hosting, displaying, uploading, modifying, publishing,
            transmitting, storing, updating, or sharing any information:
          </p>
          <p className="textGray">
            ● that belongs to any other person and to which You don’t have any
            right;
          </p>
          <p className="textGray">
            ● is defamatory, obscene, pornographic, paedophilic, invasive of
            another‘s privacy, including bodily privacy, insulting or harassing
            on the basis of gender, libellous, racially or ethnically
            objectionable, relating or encouraging money laundering or gambling,
            or otherwise inconsistent with or contrary to the laws in force
          </p>
          <p className="textGray">● is harmful to child;</p>
          <p className="textGray">
            ● infringes any patent, trademark, copyright, or other proprietary
            rights;
          </p>
          <p className="textGray">
            ● deceives or misleads the addressee about the origin of the message
            or knowingly and intentionally communicates any information which is
            patently false or misleading in nature but may reasonably be
            perceived as a fact;
          </p>
          <p className="textGray">● impersonates another person;</p>
          <p className="textGray">
            ● threatens the unity, integrity, defense, security, or sovereignty
            of India, friendly relations with foreign States, or public order,
            or causes incitement to the commission of any cognizable offence or
            prevents investigation of any offense or is insulting other nation;
          </p>
          <p className="textGray">
            ● contains a software virus or any other computer code, file, or
            program designed to interrupt, destroy or limit the functionality of
            any computer resource or otherwise permits unauthorized use of
            access to a computer or a computer network;
          </p>
          <p className="textGray">
            ● is patently false and untrue, and is written or published in any
            form, with the intent to mislead or harass a person, entity or
            agency for financial gain or to cause any injury to any person;
          </p>
          <p className="textGray">
            b. impersonating or attempting to impersonate another user, person
            or entity;
          </p>
          <p className="textGray">
            c. using the account, username, or password / OTP of another user at
            any time, disclosing your password / OTP to any third party, or
            permitting any third party to access your account;
          </p>
          <p className="textGray">
            d. using any information obtained from the Service in order to
            harass, abuse, or harm another person or entity, or attempting to do
            so;
          </p>
          <p className="textGray">
            e. using any automated system, such as but not limited to, using
            scripts to create or post Content;
          </p>
          <p className="textGray">
            f. interfering with, disrupting, or creating an undue burden on the
            Service or the networks or services connected to the Service;
          </p>
          <p className="textGray">
            g. displaying an unauthorized commercial advertisement on the
            Service, or accepting payment or anything of value from a third
            party in exchange for your performing any commercial activity
            through the unauthorized or impermissible use of the Service on
            behalf of that party;
          </p>
          <p className="textGray">
            h. reproducing, duplicating, copying, selling, reselling, trading,
            or exploiting for any commercial purposes, any portion of, or access
            to, the Content and/or functionality offered through the Service
            unless you have been specifically allowed to do so in a separate
            agreement;
          </p>
        </div>
        <BgContainer text="DISCLAIMERS" />
        <div className="textsContainer container">
          <p className="textGray">
            Our services are provided “as is” and “as available.” we disclaim
            all conditions, representations, and warranties not expressly set
            out in these terms to the fullest extent permitted by applicable
            law. We do not guarantee the accuracy, completeness, or usefulness
            of any third-party content provided through the service, or adopt,
            endorse or accept responsibility for the accuracy or reliability of
            any opinion, advice, or statement made by any party that appears in
            the service. Under no circumstances will the Company or its
            affiliates be responsible or liable for any loss or damage resulting
            from your reliance on information or other content posted in the
            Service or transmitted to or by any User/s.
          </p>
          <p className="textGray">
            The Company’s products and/or Services, including the App and
            content, are compatible only with certain
            devices/tablets/instruments/hardware. The Company is not under any
            kind of obligation to provide workable products and/or services for
            any instruments that are not recognized by the Company or those
            instruments that may be purchased from any third party which are not
            compatible with the Company’s products and Services. The Company
            reserves the right to upgrade the tablets/ type of compatible
            devices as required from time to time. Further, the Company shall
            have no responsibility for any loss or damage caused to tablet or
            any other hardware and/or software and/or instrument, including loss
            of data or effect on the processing speed, resulting from Your use
            of our products and Services.
          </p>
        </div>
        <BgContainer text="TAKEDOWN ON ANY PLATFORM" />
        <div className="textsContainer container">
          <p className="textGray">
            You acknowledge and agree that neither the Company nor its
            affiliates and third-party partners are responsible:
          </p>
          <p className="textGray">
            ● for and shall not have any liability, directly or indirectly, for
            any loss or damage, including personal injury or death, as a result
            of or alleged to be the result of any incorrect or inaccurate
            Content posted in the Service, whether caused by users or any of the
            equipment or programming associated with or utilized in the Service;
          </p>
          <p className="textGray">
            ● the timeliness, deletion or removal, incorrect delivery or failure
            to store any content, communications, or personalisation settings;
          </p>
          <p className="textGray">
            ● the conduct, whether online or offline, of any user:
          </p>
          <p className="textGray">
            ● any error, omission, or defect in, interruption, deletion,
            alteration, delay in operation or transmission, theft or destruction
            of, or unauthorized access to, any user or user communications; or
          </p>
          <p className="textGray">
            ● any problems, failure, or technical malfunction of any telephone
            network or lines, computer online systems, servers or providers,
            computer equipment, software, failure of email or players on account
            of technical problems or traffic congestion on the Internet or at
            any website or combination thereof, including injury or damage to
            users or to any other person’s computer or device related to or
            resulting from participating or downloading materials in connection
            with the Internet and/or in connection with the Service.
          </p>
        </div>
        <BgContainer text="INDEMNITY" />
        <div className="textsContainer container">
          <p className="textGray">
            You agree to defend, hold harmless and indemnify the Company
            including but not limited to its affiliates, subsidiary, parent
            company, officers, employees, directors, and agents from and against
            any and all losses, costs, expenses, damages, or other liabilities
            incurred by the Company from and against any cost, liability, loss,
            damage, cause of action, claim, suit, proceeding, demand or action
            brought by a third party against Website or the App:
          </p>
          <p className="textGray">
            (1) In connection with your use of the Services including any
            payment obligations incurred through the use of the website billing
            and payment services; or
          </p>
          <p className="textGray">(2) Resulting from:</p>
          <p className="textGray">(a) Your use of the Website or the App</p>
          <p className="textGray">
            (b) Your decision to supply credit information via the Website or
            the App, including personal financial information;
          </p>
          <p className="textGray">
            (c) Your breach of any provision of these Terms of Service;
          </p>
          <p className="textGray">
            (d) Any negligent or intentional wrongdoing by you as a User;
          </p>
        </div>
        <BgContainer text="COMPLAINTS AND GRIEVANCES" />
        <div className="textsContainer container">
          <p className="textGray">
            All complaints and grievances should be sent to us at the following
            address:
          </p>
          <p className="textGray">
            117, 1st Floor, Shoppers Point, S.V. Road, Andheri West, Mumbai,
            400058
          </p>
          <strong className="textGray">Or</strong>
          <p className="textGray">
            You may report your complaints/grievances to Grievance Officer.
          </p>
          <p className="textGray">Name: Mr. Parth Sheth</p>
          <p className="textGray">Contact E-mail: support@stepapp.ai</p>
        </div>
        <BgContainer text="MISCELLANEOUS" />
        <div className="textsContainer container mb-3 mb-md-5">
          <p className="textGray">
            Governing Law and Jurisdiction: The Terms of Use are governed by
            India's laws. Any claim related to any dispute arising as a result
            of the Services will be made before a court of competent
            jurisdiction located in Mumbai. You agree that regardless of any
            statute or law to the contrary, any claim or cause of action arising
            out of or related to the Terms of Use or use of the Services must be
            filed within one year after such claim or cause of action arose or
            be forever barred.
          </p>
          <p className="textGray">
            Headings: Headings used in these Terms are for reference purposes
            only and in no way define or limit the scope of the section.
          </p>
          <p className="textGray">
            Severability: If any provision of these Terms is held to be
            unenforceable, such provision will be reformed only to the extent
            necessary to make it enforceable.
          </p>
          <p className="textGray">
            Force Majeure: The Company shall be under no liability whatsoever in
            the event of non-availability of any portion of the Application or
            subscribed content occasioned by an act of God, war, disease,
            revolution, riot, civil commotion, strike, lockout, pandemic,
            epidemic, lockdown, flood, fire, failure of any public utility,
            manmade disaster, infrastructure failure or any other cause
            whatsoever beyond the control of the Company
          </p>
          <p className="textGray">
            Waiver: The failure of the Company to act with respect to a breach
            of these Terms of Use by you or others does not constitute a waiver
            and will not limit the Company's rights with respect to such breach
            or any subsequent breaches.
          </p>
          <p className="textGray">
            Survival: The obligations under the provisions of the Terms which by
            their nature are meant to survive the termination or cancellation of
            the subscription will survive any such termination or cancellation
            of the subscription.
          </p>
          <p className="textGray">
            Entire Agreement: These Terms and the Privacy Policy on the Platform
            constitute the entire agreement between you and the Company and
            govern your use of the Platform, superseding any prior agreements
            between you and the Company.
          </p>
        </div>
      </div>
    </SiteLayout>
  );
};

export default index;
